<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-row align-center justify-center>
        <v-flex xs12 sm12 md12>
          <v-col cols="12" md="6" class="mx-auto">
            <p class="display-1">{{ $t('confirmActiveKey.welcomeTo') }}</p>
            <p class="text-welcome">
              {{ $t('confirmActiveKey.onlineShop') }}
              <span class="font-weight-bold">CONASI365</span>
            </p>
            <p>
              {{ $t('confirmEntityCode.toContinue') }}
            </p>
            <v-form
              ref="entryForm"
              @submit.prevent="onVerifyActiveKey"
              v-model="valid"
            >
              <v-text-field
                :rules="activeKeyRules"
                v-model="activeKey"
                :label="$t('confirmEntityCode.agentCode')"
                :hint="$t('confirmEntityCode.codeForEachAgent')"
                outlined
              ></v-text-field>
              <div class="text-left">
                <v-btn color="primary" type="submit">{{ $t('common.continue') }}</v-btn>
              </div>
            </v-form>
          </v-col>
        </v-flex>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import IntroNavigation from "Components/IntroNavigation";
export default {
  components: {
    IntroNavigation,
  },
  data: () => ({
    activeKey: null,
    valid: false,
    isVerifySuccess: false,
    activeKeyRules: [],
  }),
  watch: {
    activeKey() {
      this.activeKeyRules = [];
    },
  },
  methods: {
    activeKeyNotExist () {
      return this.$t('confirmEntityCode.agentCodeDoesNotExist')
    },
    /**
     * Verify active key
     */
    onVerifyActiveKey: function() {
      this.activeKeyRules = [
        (v) => !!v || this.$t('login.thisFieldIsRequired')
      ];
      if (this.$refs.entryForm.validate()) {
        let filter = {
          code: this.activeKey
        }
        this.VERIFY_ENTITY_CODE(filter).then(
          function () {
            this.activeKeyRules = []
            this.$router.push({
              name: "AdminLogin",
            });
          }.bind(this)
        ).catch(
          function () {
            this.activeKeyRules.push(
              this.activeKeyNotExist
            )
            this.$refs.entryForm.validate()
          }.bind(this)
        )
      }
    },
    ...mapActions(["VERIFY_ENTITY_CODE"]),
  },
};
</script>

<style lang="scss" scoped>
.text-welcome {
  color: #FE6D6D !important;
  font-size: 24px;
  font-weight: 600;
}
</style>
